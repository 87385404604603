import React from "react"
import Img from 'gatsby-image'

import Layout from "../components/layout"
import SEO from "../components/seo"
import Button from '../components/Button'

import Countdown from 'react-countdown-now';

const IndexPage = ({ data }) => (
  <Layout>
    <div className="container">
      <SEO />
      <div className="columns is-vcentered is-multiline">
        <div className="is-12 column">
          <p>IDA Raadio and Narva Art Residency are happy to announce the open call for their first collaborative sound residency for musicians, music producers and sound artists coming from the Baltic States and United States of America.</p>
        </div>

        <div className="is-12 is-medium-12 column">
          <h4>About</h4>
          <p>This is a collaborative residency, meaning that two artists will be selected (one from the Baltic States and one from the US) who will work together at Narva Art Residency for two weeks in September 2019. There are no restrictions or obligations, but we expect the artists to spend some time together in the studio and share their process and possible outcome in the end of the residency – a new piece of music, performance or something completely different in the field of sound-art. We encourage professionals as well as emerging sound artists to apply.</p>
        </div>

        <div className="is-12 is-medium-12 column">
          <h4>How to apply?</h4>
          <p>To apply please send a link to your previous work and a cover letter (max 1000 characters) to <a href="mailto:hello@idaidaida.ee?subject=Sound Residency: _">hello@idaidaida.ee</a>, subject line “Sound Residency: Your Name”. The deadline for the application is <strike>June 26th</strike> June 30th, the results will be public July 1st. Participants will be selected by a committee including Ann Mirjam Vaikla (NART), Natalie Mets (IDA Raadio), Ats Luik (IDA Raadio), Robert Nikolajev (IDA Raadio), Sander Mölder (Raadio 2 / TIKS), Maria Juur (Maria Minerva / Dublab), Ingrid Kohtla (Station Narva).</p>
          <Button link="mailto:hello@idaidaida.ee?subject=Sound Residency: _" title="Apply now!" />
        </div>

        <div className="is-12 column image">
          <Img style={{ maxHeight: '90vh', width: '100%' }} fixed={data.image1.childImageSharp.fixed} />
        </div>

        <div className="is-12 column timeline">
          <h4>Timeline</h4>
          <ul className="columns is-multiline">
            <li className="is-12 column">05.06.<br />Application opens</li>
            <li className="is-12 column">30.06.<br />Application deadline</li>
            <li className="is-12 column">End of August – End of September<br /> Residency in <a href="https://www.google.com/maps/place/Narva/@59.369567,28.0401237,12z/data=!3m1!4b1!4m5!3m4!1s0x469437d78b66b087:0xb94a2a5f4d7d21f4!8m2!3d59.3796931!4d28.179075" rel="noopener noreferrer" target="_blank">Narva, Ida-Virumaa, Estonia</a></li>
            <li className="is-12 column">21.09.<br />Performance and presentation of the sound work within Station Narva 2019 <a rel="noopener noreferrer" target="_blank" href="https://www.stationnarva.ee">stationnarva.ee</a></li>
          </ul>
        </div>

        <div className="is-12 column image">
          <Img style={{ maxHeight: '90vh', width: '100%' }} fixed={data.image5.childImageSharp.fixed} />
        </div>

        <div className="is-12 column">
          <h4>Studio & recording</h4>
          <p>A studio space with basic equipment will be covered by the organisers and a professional sound engineer will be on site for the whole period. </p>
        </div>

        <div className="is-12 column">
          <h4>Transport, accommodation & catering</h4>
          <p>International and local transport and accommodation for the residency period will be covered by the organisers and both of the artists will get 33€ daily allowance for food. Special dinners and excursions to the region known for its post-industrial heritage and charming coastline will be organised. Residents will be invited to do shows on IDA Raadio. Creative residency period will be shared at the communication channels of IDA Raadio and NART.</p>
        </div>

        <div className="is-12 column image">
          <Img style={{ maxHeight: '90vh', width: '100%' }} fixed={data.image6.childImageSharp.fixed} />
        </div>

        <div className="is-12 column">
          <h4>About IDA Raadio</h4>
          <p>IDA Raadio is an independent online community radio based in the city centre of Tallinn, Estonia. Founded May 1-st 2018 it connects interesting & innovating artists from Estonia & neighbouring countries. Hosting shows from 10AM to 10PM five days a week, IDA Raadio offers a unique platform for its nearly 200 radio show hosts. IDA's music programme consists of a wide range of genres (from classical, jazz & folk to various forms of modern contemporary dance music), plus educating talk-shows from local art institutions, progressive activists and writers. IDA Raadio is being run by a group of volunteers & aims to enrich & challenge the local scenes of media & culture.</p>
          <p>Listen: <a href="https://www.idaidaida.ee/">idaidaida.ee</a></p>
        </div>

        <div className="is-12 column image">
          <Img style={{ maxHeight: '90vh', width: '100%' }} fixed={data.image3.childImageSharp.fixed} />
        </div>

        <div className="is-12 column">
          <h4>About NART</h4>
          <p>Narva Art Residency is a unique cultural platform founded in 2015 and facilitating residencies, art exhibitions, talks and educational workshops. It is located in Narva city on the Estonian-Russian border at the historical Kreenholm area. The International Artist-in-residency program is open for artists operating across a wide range of disciplines, including Visual Arts, Music, Performance, Architecture, Design, Film, Literature and Curatorial Practices. It aims to generate creative exchange between practitioners and to strengthen links with the local community. The centre is located at the historic style villa, originally built for the technology director of Kreenholm. In close proximity stands the abandoned textile factory which was once the largest of its kind in Europe. In recent times, it has functioned as a major source of inspiration for artists and a space allowing opportunity for experimenting.</p>

          <p>See & read more: <a style={{marginRight:'20px'}} href="https://www.nart.ee/en/">nart.ee</a><a href="https://www.google.com/maps/place/Narva+Art+Residence/@59.3613248,28.1924608,17z/data=!4m5!3m4!1s0x46944831410f9601:0x7d27e072e252fc12!8m2!3d59.3613221!4d28.1946495">location</a></p>
        </div>
        <div className="is-12 column image">
          <Img style={{ maxHeight: '90vh', width: '100%' }} fixed={data.image2.childImageSharp.fixed} />
        </div>
        <div className="is-12 column">
          <h4>About Narva</h4>
          <p>Narva is the third largest city in Estonia located at the eastern extreme point of the country – on the border of European Union and Russia divided by the Narva River. Through history it has had to rebuild itself several times due to war and shifting power relations in the region. It is a city that is struggling with several socio-economic aspects including shrinking population, severe vacant post-industrial heritage, unemployment, spreading of HIV etc. Nevertheless, the city is currently standing on a stepping-stone to redefine itself and to build a new future – it is running for a nomination of becoming the next European Capital of Culture in 2024.</p>
        </div>     
      </div>
      <div className="is-12 column countdown">
        <h4>Time left</h4>
        <h1>
          <Countdown date={new Date(1561928401000)} />
        </h1>
        <Button link="mailto:hello@idaidaida.ee?subject=Sound Residency: _" title="Apply now!" />
      </div>
      <div style={{marginTop: '200px'}} className="is-12 colophon column">
        <p className="small no-indent">Photos: Tõnu Tunnel, Kaupo Rõivasepp</p>
      </div>   
    </div>
  </Layout>
)

export default IndexPage

export const pageQuery = graphql`
  query AnnouncementQuery {
    image1: file(relativePath: {eq: "8.jpg"}) {
      childImageSharp {
        fixed(width: 1600) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    image2: file(relativePath: {eq: "2.jpg"}) {
      childImageSharp {
        fixed(width: 1600) {
          ...GatsbyImageSharpFixed
        }
      }
    }
     image3: file(relativePath: {eq: "10.jpg"}) {
      childImageSharp {
        fixed(width: 1600) {
          ...GatsbyImageSharpFixed
        }
      }
    }
     image4: file(relativePath: {eq: "4.jpg"}) {
      childImageSharp {
        fixed(width: 1600) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    image5: file(relativePath: {eq: "5.jpg"}) {
      childImageSharp {
        fixed(width: 1600) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    image6: file(relativePath: {eq: "6.jpg"}) {
      childImageSharp {
        fixed(width: 1600) {
          ...GatsbyImageSharpFixed
        }
      }
    }
     image7: file(relativePath: {eq: "7.jpg"}) {
      childImageSharp {
        fixed(width: 1600) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    image8: file(relativePath: {eq: "8.jpg"}) {
      childImageSharp {
        fixed(width: 1600) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    image9: file(relativePath: {eq: "9.jpg"}) {
      childImageSharp {
        fixed(width: 1600) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`